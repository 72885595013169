<template>
  <div class="valuation_information">
    <div class="vi_top">
      <div class="vi_top_left">
        <!-- <div class="vi_title"></div> -->
        <div class="vi_price">预估市场价 - ￥{{assess_price}}</div>
      </div>
      <router-link :to="{name:'SiteNotice'}" class="vi_top_right">返回</router-link>
    </div>
    <div class="vi-middle">
      <div class="vi-middle-left">
        <div class="VN_left_title"><img src="@/assets/image/AccountValuation/gj1.png"><span>官网寄售-建议市场预估价</span></div>
        <hr class="dividing_line"/>
        <ul class="VN_list">
          <li class="VN_list_one">美工截取账号详情图，编辑标题挂售到官方网站（约30分钟）。</li>
          <li class="VN_list_two">平台在交流群等渠道同步宣传，提高曝光度以匹配更多买家。</li>
          <li class="VN_list_three">买家确认交易并完成付款，由客服拉群聊安排交易流程。</li>
          <li class="VN_list_four">买卖双方游戏资料交接完成后，财务实时放款，交易完成。</li>
        </ul>
        <div class="VN_price">预估到手价：<span>￥{{assess_price}}</span></div>
        <div class="VN_left_btn">
          <span>数据直接导入<i class="el-icon-caret-right"></i></span>
          <router-link :to="{name:'AccountSell',query:{product_id}}">一键寄售</router-link>
        </div>
      </div>
      <div class="vi-middle-center">
        <div class="VN_left_title"><img src="@/assets/image/AccountValuation/gj2.png"><span>担保中介-参考市场价自定价格</span></div>
        <hr class="dividing_line"/>
        <ul class="VN_list">
          <li class="VN_list_one">双方私下联系谈好价格，确认账号详情后联系客服。</li>
          <li class="VN_list_two">客服直接拉组中介交易。</li>
          <li class="VN_list_three">担保中介不限游戏，支持市面所有游戏账号交易。</li>
        </ul>
        <hr class="dividing_line"/>
        <div class="VN_left_btn color-fff">
          <a v-if="game_id===10" @click="cusSerBtnClicks" href="#">联系客服</a>
          <a v-else @click="$accessLink(sell_customer)"
                 :href="`${sell_customer}?u_cust_id=${$store.state.userData.user_id}&u_cust_name=${$store.state.userData.nickname}`" target="_blank">联系客服</a>
        </div>
      </div>
      <div class="vi-middle-right">
        <div class="VN_left_title"><img src="@/assets/image/AccountValuation/gj3.png"><span>号商回收-一般是市场价的5-8折</span></div>
        <hr class="dividing_line"/>
        <ul class="VN_list">
          <li class="VN_list_one">号商对账号进行报价，号主决定是否回收。</li>
          <li class="VN_list_two">快速变现，当天收款。</li>
          <li class="VN_list_three">所有交易一律由官方客服拉组担保中介交易。</li>
          <li class="VN_list_four">合作号商并非官方人员。</li>
        </ul>
        <hr class="dividing_line"/>
        <div class="VN_left_btn color-fff">
          <router-link :to="{name:'AccountRecycle',query:{game_id}}">联系客服</router-link>
        </div>
      </div>
    </div>
    <div class="vi-message">
      <div class="msg-top">
        <div class="msg-left">客服留言：</div>
        <router-link :to="{name:'AccountValuation'}" class="msg-right">继续估价</router-link>
      </div>
      <div class="msg-con">{{assess_price_note}}</div>
    </div>
    <div class="vi_connect_box">
      <div class="vi_title">估价信息</div>
      <div class="vi_connect" v-html="game_name+' / '+valuationInfo">{{game_name}} / {{valuationInfo}}</div>
    </div>
  </div>
</template>

<script>
import {apiValuationDetail,apiGameList} from "@/request/API";
export default {
  name:'ValuationInformation',
  data() {
    return {
      // 价格
      assess_price: '',
      // 详细信息
      valuationInfo: '',
      game_id: this.$route.query.game_id,
      game_name: '',
      // 客服留言
      assess_price_note: '',
      product_id: this.$route.query.product_id,
      sell_customer: ''
    }
  },
  methods:{
    cusSerBtnClicks() {
      let routeData = this.$router.resolve({
        name: 'CustomerSer',
        query: {
          game_id: this.game_id
        }
      })
      window.open(routeData.href, '_blank')
    },
    getGameData(){
      apiValuationDetail({
        id:this.product_id
      }).then(res => {
        this.assess_price = res.data.assess_price
        this.assess_price_note = res.data.assess_price_note
        if(this.assess_price_note == '') this.assess_price_note ='暂无留言'
        this.game_id = res.data.game_id
        this.valuationInfo = res.data.category.map(obj => `${obj.cat_name}:${obj.attr_name}`).join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
        this.sell_customer = res.data.sell_customer
      })
      apiGameList().then(res => {
        res.data.forEach((item, index) => {
          if(item.game_id == this.game_id){
            this.game_name = item.game_name
          }
        })
      })
    }
  },
  mounted(){
    this.getGameData()
  }
}
</script>

<style lang="less" scoped>
.valuation_information{
  width: 1100px;
  // background-color: #fff;
  margin-top: 25px;
  // padding: 40px 50px;
  .vi_top{
    height: 100px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    background: linear-gradient(90deg,#ffc092, #ff7e35 100%);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    .vi_top_left{
      .vi_price{
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 100px;
        text-indent: 42px;
      }
    }
    .vi_top_right{
      // height: 40px;
      line-height: 100px;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      margin-right: 44px;
    }
  }
  .vi-middle{
    display: flex;
    justify-content: space-between;
    >div{
      flex: 1;
      background-color: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 25px 30px;
      position: relative;
    }
    .dividing_line{
        margin: 0;
        background-color: #f1f1f1;
        border: none;
        height: 1px;
      }
    // .vi-middle-left{
      .VN_left_title{
          margin-bottom: 16px;
          img{
            width: 30px;
            height: 30px;
            margin-right: 11px;
            vertical-align: middle;
          }
          span{
            color: #0a0a0a;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
          }
        }
        .VN_list{
          padding-top: 20px;
          li{
            margin-bottom:20px;
            padding-left: 26px;
            position: relative;
            &.VN_list_one::before,&.VN_list_three::before,&.VN_list_four::before,&.VN_list_two::before{
              content: '1';
              display: inline-block;
              position: absolute;
              left: 0;
              top: 3px;
              border: 1px solid #515151;
              width: 16px;
              height: 16px;
              text-align: center;
              border-radius: 17px;
            }
            &.VN_list_two::before{
              content: '2';
            }
            &.VN_list_three::before{
              content: '3';
            }
            &.VN_list_four::before{
              content: '注';
              font-size: 12px;
              color: #FCAD13;
              border-color: #FCAD13;
            }
          }
        }
        .VN_price{
          border: 1px solid #f1f1f1;
          border-left: none;
          border-right: none;
          line-height: 40px;
          span{
            color: #FF8900;
            font-weight: 500;
            font-size: 24px;
          }
        }
        .VN_left_btn{
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          span{
            display: inline-block;
            line-height: 30px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #bebebe;
          }
          a{
            display: inline-block;
            width: 122px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            color: #000000;
            font-size: 16px;
            background: #ffdb51;
            border-radius: 29px;
            margin-left: 11px;
          }
        }
        .color-fff{
          a{
            background-color: #fff;
            border: 1px #000000 solid;
          }
        }
    // }
    .vi-middle-center{
      margin: 0 12px 0;
    }
    .vi-middle-right{}
  }
  .vi-message{
    margin: 12px 0;
    background-color: #fff;
    border-radius: 10px;
    padding: 25px 30px;
    .msg-top{
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .msg-left{
        font-size: 16px;
        font-weight: 500;
      }
      .msg-right{
        display: inline-block;
        width: 122px;
        height: 30px;
        background: #ffdb51;
        border-radius: 29px;
        text-align: center;
        line-height: 30px;
        font-size: 16px;
      }
    }
  }
  .vi_title{
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
  .vi_connect_box{
    padding: 25px 30px;
    background-color: #fff;
    .vi_connect{
      margin-top: 10px;
      font-size: 14px;
      line-height: 30px;
      color: #3e3c3c;
    }
  }
}
</style>
